import { ChangeEvent, FC, HTMLAttributes } from 'react';
import block from 'utils/bem-css-module';
import { getPriceInRub } from 'utils/price';

import style from './header-user.scss';

type Props = {
	avatar: string;
	balance: number;
}

const b = block(style);


const DEFAULT_AVATAR = '/static/img/avatar.png';

export const HeaderUser: FC<Props & HTMLAttributes<HTMLDivElement>> = ({
	avatar = DEFAULT_AVATAR,
	className,
	balance,
	...rest
}) => {
	const onError = (e: ChangeEvent<HTMLImageElement>) => {
		e.target.src = DEFAULT_AVATAR;
	};
	
	return (
		<div className={className} {...rest}>
			<img
				src={avatar}
				className={b('avatar')}
				alt='фотография пользователя'
				width='28'
				height='28'
				loading='lazy'
				onError={onError}
			/>
			<p className={b('balance')}>
				Баланс : {getPriceInRub(balance)}
			</p>
		</div>
	);
};
