import { FC, HTMLAttributes } from 'react';
import block from 'utils/bem-css-module';

import { Language } from 'types/common';

import style from './language-dropdown.scss';


type Props = {
	language: Language;
}

const b = block(style);

export const LanguageDropdown: FC<Props & HTMLAttributes<HTMLElement>> =({
	language,
	className = '',
	...rest
}) => {
	return (
		<div className={b({ lang: Object.keys(Language).find(k => Language[k] == language)?.toLowerCase() }).mix(className)} {...rest}>
			{language}
		</div>
	);
};
